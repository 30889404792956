<template>
  <div class="form">
    <!-- 组1 -->
    <div class="weui-cells">
      <ainput
        type="text"
        text="姓名"
        :model.sync="form.name"
        value="张工"
        placeholder="请输入"
        :required="true"
        readonly
      ></ainput>
      <ainput
        type="number"
        text="电话"
        :model.sync="form.mobile"
        value="13305423032"
        placeholder="请输入"
        :required="true"
        readonly
      ></ainput>
      <apicker
        :options="sex"
        text="性别"
        :model.sync="form.gender"
        placeholder="请选择"
      ></apicker>
    </div>

    <!-- 组2 -->
    <common-position
      @change="choosePostion"
      :defalutForm="position"
      text="常驻国家"
    ></common-position>
    <!-- 组3 -->
    <common-switch @change="change" :form="form"></common-switch>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import { importUser, countryList, getAllcounrty } from "@/api/api";
import { getOrganization } from "@/api/search";
import countries from "@/lib/world.js";
import webview from "@/mixins/webview";
import commonSwitch from "@/components/common/organization";
import commonPosition from "@/components/common/position";
export default {
  data() {
    return {
      name: "",
      form: {
        institution: "",
        country: "",
        project: "",
      },
      sex: [
        {
          label: "男",
          value: "男",
        },
        {
          label: "女",
          value: "女",
        },
      ],
      organization: [],
      countries: [],
      projects: [],
      types: [
        {
          label: "身份证",
          value: "0",
        },
        {
          label: "护照",
          value: "1",
        },
      ],
      type: 0,
      country: [],
      position: {},
      personType: [
        {
          value: 0,
          label: "自有员工",
        },
        {
          value: 1,
          label: "外聘员工",
        },
        {
          value: 2,
          label: "务工员工",
        },
      ],
    };
  },
  components: {
    ainput,
    apicker,
    commonSwitch,
    commonPosition,
  },
  async created() {
    this.isWebView();
    this.form = JSON.parse(this.$store.state.userInfo.detail);
    this.position = {
      country: this.form.alwaysCountry,
      province: this.form.province,
      city: this.form.city,
      district: this.form.district,
      aimAddress: this.form.addressDetail,
    };
    this.getCountryList();
  },
  mixins: [webview],

  methods: {
    choosePostion(e) {
      this.form.addressDetail = e.aimAddress;
      this.form.alwaysCountry = e.country;
      this.form.province = e.province;
      (this.form.district = e.district), (this.form.city = e.city);
    },
    submit() {
      let mobile = this.$store.state.userInfo.mobile;
      this.form.isConfirm = 1;
      let flag = true;
      let requireList = [
        "name",
        "phone",
        "nation",
        "alwaysCountry",
        "institution",
      ];
      for (let k in this.form) {
        if (this.form[k] == "" && requireList.indexOf(k) > -1) {
          flag = false;
        }
      }
      if (flag) {
        importUser(mobile, this.form).then((res) => {
          this.$store.state.userInfo.detail = JSON.stringify(res.data.data);
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.replace("/index");
          }
        });
      } else {
        this.$weui.toast("请填写完整表单", {
          duration: 1500,
          className: "error",
        });
      }
    },
    async getCountryList() {
      let res = await getAllcounrty();
      let arr = res.data.data.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      arr.unshift({ label: "中国", value: "中国" });
      this.country = arr;
    },
    judgement() {
      return this.form.institution && this.form.institution.indexOf("总部") < 0;
    },
    async getOptions(query) {
      let res = await getOrganization(query);
      return res.data.data;
    },
    change(e) {
      this.form = { ...this.form, ...e };
    },
  },
};
</script>

<style>
</style>